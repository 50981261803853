import React from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import { getImage } from 'gatsby-plugin-image';
import { CallToAction } from '@/components/CallToAction';
import { Social } from '@/components/Social';
import { BreakpointContext, useBreakpoints } from 'react-use-breakpoints';
import Layout, { Main } from '../../components/Layout';
import { PostCard } from '../../components/PostCard';
import { Newsletter } from '../../components/Newsletter';
import { Content } from '../../components/Content';
import { Seo } from '../../components/SEO';
import { StyledBlog } from './styles';

interface IBlogPageTemplate {
  title: string;
  articles: any;
}

interface IBlogPage {
  data: any;
  location: any;
}

const BlogPageTemplate = ({ articles }: IBlogPageTemplate) => {
  const breakpoints = React.useContext(BreakpointContext);
  const { breakpoint } = useBreakpoints(breakpoints);
  const isUnderTablet = [`watch`, `phone`, `phablet`].some(
    (item) => item === breakpoint,
  );
  const shouldShowVerticalPostCard = isUnderTablet;

  return (
    <>
      <Content restrictWidth>
        {articles.map(({ node: article }, idx) => {
          const { publishDate, ...rest } = article;
          return idx === 0 ? (
            <PostCard
              hero
              key={rest.id}
              vertical
              date={publishDate}
              {...rest}
            />
          ) : null;
        })}
      </Content>
      <Content className="content">
        {articles.map(({ node: article }, idx) => {
          const { publishDate, ...rest } = article;
          return idx > 0 ? (
            <PostCard
              key={rest.id}
              date={publishDate}
              {...rest}
              vertical={shouldShowVerticalPostCard}
            />
          ) : null;
        })}
      </Content>
      <Newsletter id="newsletter_blog" hor />
    </>
  );
};

const BlogPageDesktopTemplate = ({ articles }: IBlogPageTemplate) => (
  <div className="body">
    <Content className="main content" noMargin>
      {articles.map(({ node: article }, idx) => {
        const { publishDate, ...rest } = article;
        return idx > 0 ? (
          <PostCard key={rest.id} date={publishDate} {...rest} />
        ) : null;
      })}
    </Content>
    <div className="spacer" />
    <div className="aside">
      <Newsletter id="newsletter_article" forceVert />
    </div>
  </div>
);

const BlogPage = ({ data, location }: IBlogPage) => {
  const articles = get(data, `allContentfulArticle.edges`);
  const page = get(data, `contentfulPage`);
  const {
    title,
    metaDescription,
    metaTitle,
    templateKey,
    heroImage,
    svgTopPath,
    svgBottomPath,
    slug,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = page;
  const image = getImage(heroImage);
  const ctaIcon = getImage(pageCTAIcon);

  return (
    <StyledBlog>
      <Layout
        location={location}
        coverImage={image}
        topSvg={svgTopPath.svg.content}
        bottomSvg={svgBottomPath.svg.content}
        desktopHeading={
          <Content restrictWidth>
            {articles.map(({ node: article }, idx) => {
              const { publishDate, ...rest } = article;
              return idx === 0 ? (
                <PostCard
                  hero
                  key={rest.id}
                  vertical
                  date={publishDate}
                  {...rest}
                />
              ) : null;
            })}
          </Content>
        }
        desktopBody={
          <BlogPageDesktopTemplate title={title} articles={articles} />
        }
        desktopCta={
          <Content>
            <CallToAction
              hookId={ctaHookId}
              icon={ctaIcon}
              title={ctaTitle}
              body={ctaBody.ctaBody}
              maxWidth="100%"
              className="cta"
            />
            <Social />
          </Content>
        }
      >
        <Seo
          title={title}
          metaTitle={metaTitle}
          metaDesc={metaDescription}
          cover={image}
          slug={slug}
          templateKey={templateKey}
        />
        <Main background="transparent" disableVertPadding={false}>
          <BlogPageTemplate title={title} articles={articles} />
        </Main>
      </Layout>
    </StyledBlog>
  );
};

export const blogPageQuery = graphql`
  query BlogPage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      templateKey
      title
      slug
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      metaTitle
      metaDescription
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 100, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
    allContentfulArticle(sort: { fields: [updateDate], order: DESC }) {
      edges {
        node {
          templateKey
          id
          title
          slug
          timeToRead
          updateDate(formatString: "MMMM Do, YYYY")
          tags
          createDate(formatString: "MMMM Do, YYYY")
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            description
            gatsbyImageData(
              width: 350
              height: 196
              quality: 85
              placeholder: BLURRED
            )
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          excerpt
          author {
            name
            slug
            image {
              gatsbyImageData(
                width: 40
                height: 40
                quality: 100
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;
